<template>
  <d-card v-if="empty(providerId)" class="sp-mt-6  card--info">
    <d-card-body :title="$t('on_boarding.no_provider_id.title')">
      {{ $t('on_boarding.no_provider_id.message') }}
    </d-card-body>
  </d-card>
  <section v-else>

    <div class="h4  text-center  sp-mb-3">{{ $t('on_boarding.title', [providerName]) }}</div>

    <d-form-invalid-feedback v-if="errors.length > 0" :force-show="true">
      <template v-for="(error, index) in errors">
        <div class="text-center" :key="index" >{{ error }}</div>
      </template>
    </d-form-invalid-feedback>

    <d-card v-if="providerFound !== null && providerFound === false">
      <d-card-body :title="$t('on_boarding.provider_not_found.title')">
        {{ $t('on_boarding.provider_not_found.message', [providerId]) }}
      </d-card-body>
    </d-card>

    <template v-else-if="hipayAccountLoaded">

      <template v-if="!isNamedRouteActive('onBoarding.initial')">

        <div class="process-steps  process-steps--main  process-steps--horizontal  sp-mb-4">
          <ul class="process-steps__steps">
            <template v-for="i in maxSteps">

              <li class="process-steps__item  process-steps__item--number" :class="{ 'is-active': i === step, 'is-completed': step > i }" :key="i">
                <span class="process-steps__icon">
                  <span class="process-steps__number">{{ i }}</span>
                </span>

                <div class="process-steps__title">{{ $t("on_boarding.steps_header.step_" + i)}}</div>
              </li>
            </template>
          </ul>
        </div>

        <d-card class="sp-p-3  sp-mb-4" style="max-width: 625px;">
          <router-view/>
        </d-card>

      </template>

      <registration-state v-else-if="formData.identificationStatus !== null" :provider-id="providerId"></registration-state>

    </template>

  </section>
</template>

<script>


import { mapFields } from 'vuex-map-fields';
import {mapFileFields, mapProviderFields, mapRegistrationFields} from "~/store";
import RegistrationState from "~/components/onBoarding/RegistrationState";

export default {
  name: "OnBoarding",
  components: {RegistrationState},

  props: {
    providerId: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      errors: [],
      providerName: '',
      shareholderStep: false,
      completeRegisterStep: false,
      maxSteps: 5,
      providerFound: null,
      hipayAccountLoaded: false,
    };
  },

  computed: {
    ...mapProviderFields([
      'formData',
      'hipayAgreed',
    ]),
    ...mapFileFields([
      'docID',
      'docCOC',
      'docAOA',
      'documentsToValidate',
      'validatedDocuments'
    ]),
    ...mapFields([
      'step'
    ]),
    ...mapRegistrationFields([
      'firstTime',
    ]),
  },

  mounted() {
    this.loadProvider()
  },

  methods: {

    gotoStep(evt) {
      this.step = evt.step
      if(evt.step === null) this.checkHiPayRegistration()

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }, 200)
    },

    onCheckStatus() {
      window.location.reload();
    },

    loadProvider() {

      this.errors = []

      this.$apollo.query({
        fetchPolicy: 'no-cache',
        variables: {
          id: this.providerId,
        },
        query: require('~/graphql/onBoarding/GetProviderByID.gql'),
      }).then((response) => {

        // Fill the Form with prefilled data of the provider
        this.provider = response.data.provider
        this.providerFound = this.provider !== null

        if(this.provider !== null) {

          this.providerName = this.provider.name

          this.formData.owner.gender = (this.provider.owner.gender !== null) ? this.provider.owner.gender.toString() : "";
          this.formData.owner.firstName = this.provider.owner.firstName;
          this.formData.owner.lastName = this.provider.owner.lastName;
          this.formData.owner.birthdate = this.provider.owner.birthdate;
          this.formData.owner.email = this.provider.owner.email;

          this.formData.administrativeContact.gender = (this.provider.administrativeContact.gender !== null) ? this.provider.administrativeContact.gender.toString() : "";
          this.formData.administrativeContact.firstName = this.provider.administrativeContact.firstName;
          this.formData.administrativeContact.lastName = this.provider.administrativeContact.lastName;
          this.formData.administrativeContact.birthdate = this.provider.administrativeContact.birthdate;
          this.formData.administrativeContact.email = this.provider.administrativeContact.email;

          this.formData.locale = this.provider.locale;
          this.formData.legalName = this.provider.legalName;
          this.formData.address = this.provider.location.address.street;
          this.formData.zipcode = this.provider.location.address.zipCode;
          this.formData.city = this.provider.location.address.city;
          this.formData.country = this.provider.location.address.country;
          this.formData.currency = this.provider.currency;
          this.formData.email = this.provider.email;
          this.formData.ubo = this.provider.ubo;
          this.formData.uboStatementStatus = this.provider.uboStatementStatus;
          this.formData.legalEntity = this.provider.legalEntity;
          (this.provider.businessType !== null) ?  this.formData.businessType = this.provider.businessType : this.businessType = "";
          // this.formData.legalEntity = this.provider.businessStructure;
          this.formData.cocID = this.provider.cocID;
          this.formData.vatID = this.provider.vatID;

          this.formData.bankAccount = this.provider.bankAccount;
          this.formData.bankCountry = this.provider.bankCountry;
          this.formData.swift = this.provider.swift;

          this.formData.documents = this.provider.documents;
          this.formData.identificationStatus = this.provider.identificationStatus;
          this.formData.uboStatementStatus = String(this.provider.uboStatementStatus);
          this.formData.ubo = (this.provider.ubo.length > 0) ? this.provider.ubo : [];
        }

        this.checkHiPayRegistration()
      }).catch((error) => {

        console.error(error)
        // Somehow the error message give twice, so directly use the graphQL error
        this.errors.push(error.graphQLErrors[0].message);
      })

    },

    checkHiPayRegistration() {

      // Add the Check the provider
      let variables = {
        providerID: this.providerId
      }

      // Check the status of the registration
      try {
        this.$apollo.query({
          query: require('~/graphql/onBoarding/GetHiPayAccountByID.gql'),
          variables,
          fetchPolicy: 'no-cache',
          // This igoners the console.log(error) in the catch error
          errorPolicy: 'ignore'
        }).then((data) => {

          if(data.data !== null && data.data.hiPayAccount.identified === 1 && data.data.hiPayAccount.bankInfoValidated === 1) {
            this.$router.push({ name: 'dashboard', params: { providerID: this.providerId } })
            return
          }

          if(data.data !== null && data.data.hiPayAccount.userAccountID !== null) {
            // Account has already been created when there is a accountID
            this.firstTime = false
          } else this.step = 1

          this.hipayAccountLoaded = true

        }).catch((error) => {
          // The Account is not available: proceed the firtstime registration
          console.log(error)
        })
      } catch (error) {
        alert(error.errors[0].message)
      }
    },

  }
}
</script>