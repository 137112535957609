<template>
  <component :is="tag" class="process-steps__item" :class="stateType">

    <span class="process-steps__icon">
      <i class="material-icons">{{ icon }}</i>
    </span>

    <div class="process-steps__content">
      <div class="process-steps__pre-title">{{ $t('on_boarding.step') }} {{ id }}</div>
      <div class="process-steps__title">{{ $t('on_boarding.steps_header.step_' + id) }}</div>
      <div class="process-steps__message" v-if="!empty(message)" v-html="message"></div>
    </div>

    <div class="process-steps__button" v-if="!(id === 5 || !firstTime && id === 1)">

      <router-link class="btn btn-primary" :to="{ name: ('onBoarding.step' + id), params: {providerId: providerId}}">
        <i class="material-icons">edit</i>
      </router-link>

    </div>

  </component>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapRegistrationFields} from "~/store";

export default {

  name: "ProcessStep",
  props: {
    id: Number,
    stateType: String,
    message: String,
    tag: {
      type: String,
      default: 'li'
    },
    providerId: {
      type: String,
      required: true,
    }
  },

  computed: {
    ...mapFields([
      'step'
    ]),
    ...mapRegistrationFields([
      'firstTime',
    ]),

    icon () {
      switch (this.stateType) {

       case 'success':
         return 'check_circle';

       case 'warning':
         return 'report_problem';

       case 'error':
         return 'error';

        default:
          return 'info';
      }

    }
  },
}
</script>
