<template>

  <d-card>
    <d-card-body>
      <div class="process-steps  process-steps--vertical">
        <ul class="process-steps__steps">
          <template v-for="(item, index) in totalSteps">
            <process-step
              :key="index + 1"
              :id="item.id"
              :stateType="item.stateType"
              :message="item.message"
              :providerId="providerId"
            ></process-step>
          </template>
        </ul>
      </div>
    </d-card-body>
  </d-card>

</template>

<script>
import {legalEntity} from "~/onBoardingSettings"
import {mapFileFields, mapProviderFields, mapRegistrationFields} from "~/store";
import {mapFields} from "vuex-map-fields";
import mixins from '~/mixins';
import ProcessStep from "@/components/onBoarding/ProcessStep";

export default {
  name: "RegistrationState",
  components: {ProcessStep},
  mixins: [mixins],

  props: {
    providerId: {
      type: String,
      required: true,
    }
  },

  mounted() {
    this.checkStatus()
  },

  computed: {
    ...mapProviderFields([

      'formData.businessType',
      'formData.legalEntity',
      'formData.country',

      'formData.documents',
      'formData.ubo',
      'formData.uboStatementStatus',
    ]),
    ...mapFileFields([
      'docID',
      'docCOC',
      'docAOA',
      'documentsToValidate',
      'validatedDocuments'
    ]),
    ...mapFields([
      'step'
    ]),
    ...mapRegistrationFields([
      'firstTime',
      'totalSteps',
    ])
  },

  methods: {

    checkStatus() {

      this.checkRegistrationStatus()
      this.checkDocumentStatus()
      this.checkUboStatus()
      this.checkBankStatus()
    },

    checkRegistrationStatus() {

      if(!this.firstTime) {
        this.totalSteps[0].stateType = "success"
        this.totalSteps[0].message = this.$t('on_boarding.registration_state.done')
      }
    },

    checkDocumentStatus() {

      if(this.totalSteps[0].stateType !== "success") return

      this.totalSteps[1].message = "";

        // First check it's legal entity to check the correct documents
      this.updateHipayBusinessType(this.legalEntity);

      // Check which documents need to be uploaded based on the documents status
      this.documents.forEach(item => {

        if (item.type === "ID" && item.status === "Validated") {
          this.docID = false;
          this.validatedDocuments.push(1)
        } else if (item.type === "ID" && item.status === "Waiting") {
          this.totalSteps[1].stateType = "warning"
          this.totalSteps[1].message += this.$t('on_boarding.registration_state.document_status', [item.type, item.status]) + '<br/>'
        } else if(item.type === "ID") {
          this.totalSteps[1].message += this.$t('on_boarding.registration_state.document_status', [item.type, item.status]) + '<br/>'
        }
        if (item.type === "ChamberOfCommerce" && item.status === "Validated") {
          this.docCOC = false
          this.validatedDocuments.push(2)
        } else if (item.type === "ChamberOfCommerce" && item.status === "Waiting") {
          this.totalSteps[1].stateType = "warning"
          this.totalSteps[1].message += this.$t('on_boarding.registration_state.document_status', [item.type, item.status]) + '<br/>'
        } else if(item.type === "ChamberOfCommerce") {
          this.totalSteps[1].message += this.$t('on_boarding.registration_state.document_status', [item.type, item.status]) + '<br/>'
        }
        if (item.type === "ArticlesOfAssociation" && item.status === "Validated") {
          this.docAOA = false
          this.validatedDocuments.push(3)
        } else if (item.type === "ArticlesOfAssociation"  && item.status === "Waiting") {
          this.totalSteps[1].stateType = "warning"
          this.totalSteps[1].message += this.$t('on_boarding.registration_state.document_status', [item.type, item.status]) + '<br/>'
        } else if(item.type === "ArticlesOfAssociation") {
          this.totalSteps[1].message += this.$t('on_boarding.registration_state.document_status', [item.type, item.status]) + '<br/>'
        }
      })

      let allValid = !this.documents.some( item => item.status !== "Validated" && item.type !== 'Bank')

      if (allValid) {
        this.totalSteps[1].stateType = "success"
        this.totalSteps[1].message = this.$t('on_boarding.registration_state.done')
      }

    },

    checkUboStatus() {
      this.totalSteps[2].message = ''

      if (this.uboStatementStatus === "waiting") {
        this.totalSteps[2].stateType = "warning"
        this.totalSteps[2].message = this.$t('on_boarding.registration_state.waiting')
      }
      else {
        this.totalSteps[2].message = this.$t('on_boarding.registration_state.no_feedback')
      }

      this.ubo.forEach(ubo => {
        this.totalSteps[2].message += this.$t('on_boarding.registration_state.ubo_status', [ubo.id, ubo.status]) + '<br/>'
        if(ubo.status === 'waiting') {
          this.totalSteps[2].stateType = "warning"
        }
        ubo.documents.forEach(doc => {
          this.totalSteps[2].message += this.$t('on_boarding.registration_state.ubo_file', [doc.type, doc.status]) + '<br/>'
        })
      })

    },

    checkBankStatus() {

      this.totalSteps[3].message = ''
      this.documents.forEach(item => {
        if (item.type === "Bank" && item.status === "Validated") {
          this.totalSteps[3].stateType = "success"
          this.totalSteps[3].message = this.$t('on_boarding.registration_state.done')
        } else if (item.type === "Bank" && item.status === "Waiting") {
          this.totalSteps[3].stateType = "warning"
          this.totalSteps[3].message += this.$t('on_boarding.registration_state.document_status', [item.type, item.status]) + ' '
        }
      })
    },

    updateHipayBusinessType(event) {

      legalEntity[(!this.empty(this.country)) ? this.country : 'GB'].map(item => {
        if(item.legalEntity === event) {
          this.businessType = item.hipayEntity
          this.documentsToValidate = item.documents
          item.documents.forEach( item => {
            if(item === 1) this.docID = true;
            if(item === 2) this.docCOC = true;
            if(item === 3) this.docAOA = true;
          })
        }
      })
    },
  }
}
</script>